





















import { Component, Vue, Prop } from "vue-property-decorator";
import ProgressBarCircle from "@/components/ProgressBar/ProgressBarCircle.vue";
import ProgressBarLinear from "@/components/ProgressBar/ProgressBarLinear.vue";

@Component({
  components: { ProgressBarLinear, ProgressBarCircle },
})
export default class LMSDashboardLink extends Vue {
  public name = "LMSDashboardLink";

  @Prop({ default: () => "", type: String })
  public imgSrc!: any;

  @Prop({ default: () => "", type: String })
  public title!: any;

  @Prop({ default: () => "", type: String })
  public link!: any;

  @Prop({ default: () => "", type: String })
  public footerBackgroundColor!: any;

  // In Future, we can use maybe a more proper way, but this one for a temporary solution
  public handleClick(link: string): void {
    // Find the <a> element with the specified href
    const targetLink = document.querySelector(`a[href="${link}"]`);

    // Check if the element exists, then trigger a click
    if (targetLink instanceof HTMLAnchorElement) {
      targetLink.click();
    } else {
      console.log(`Link with href="${link}" not found.`);
    }
  }
}
