



























import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import VerticalDivider from "@/components/VerticalDivider.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import MailButton from "@/components/Button/MailButton.vue";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import ShoppingCartButton from "@/components/Button/ShoppingCartButton.vue";
import QrcodeButton from "@/components/Button/QrcodeButton.vue";
import SaldoButton from "@/components/Button/SaldoButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";

const LMSQuestionTutorModule = namespace("lms");

@Component({
  components: {
    CreateButton,
    EditButton,
    SaldoButton,
    QrcodeButton,
    ShoppingCartButton,
    FileCsvButton,
    ActionButton,
    CustomSlider,
    MailButton,
    Avatar,
    VerticalDivider,
    Table,
    FscCard,
  },
})
export default class QuestionList extends mixins() {
  public name = "QuestionList.vue";

  @LMSQuestionTutorModule.Action("filter")
  public findAll: any;

  @LMSQuestionTutorModule.Action("saveSearchTerm")
  public saveSearchTerm: any;

  @LMSQuestionTutorModule.Getter("getTotal")
  public totalRows: any;

  @LMSQuestionTutorModule.Getter("getDataList")
  public items: any;

  @LMSQuestionTutorModule.Getter("getIsLoading")
  public questionListIsLoading: any;

  protected query: any = {};
  protected queryCached: any = {};
  @Prop({ default: () => null, type: String })
  private text = "was kan";

  @Prop({ default: () => false, type: Boolean })
  public useCurrentQuery = false;

  public searchWithToken(searchString: any, saveSearch: boolean) {
    this.text = searchString;
    this.doSearch();
    if (saveSearch) {
      this.saveSearchTerm(this.text);
    }
  }

  public setToken(searchString: any, query: any) {
    this.text = searchString;
    this.useCurrentQuery = true;
    this.queryCached = query;
  }

  public doSearch() {
    this.query = { ...this.query, limit: 25, offset: 0 };
    this.findAll({
      resource: "standard-question-explanation",
      filter: {
        text: this.text,
        ...this.query,
      },
    });
  }

  public getQuestions() {
    return this.items;
  }

  public fields = [
    {
      key: "officialNumber",
      label: "amtl. Nr.",
      sortable: false,
      class: "w-20",
    },
    {
      key: "title",
      label: "Frage",
      sortable: false,
      class: "w-50",
    },
    {
      key: "questionClass",
      label: "Klasse",
    },
    {
      key: "points",
      label: "Punkte",
    },
  ];

  public async requestQuery(query: Record<any, any>): Promise<any> {
    if (this.queryCached) {
      console.log("using current query...");
      console.log(this.queryCached);
      query = this.queryCached;
    } else {
      console.log("using fresh query...");
    }
    console.log("questionSearchTerm ", this.text);

    this.findAll({
      resource: "standard-question-explanation",
      filter: {
        text: this.text,
        ...query,
      },
    });
    this.queryCached = null;
    this.$emit("on-queried", query);
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.$emit("on-question-selected", ctx);
  }
}
