


































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { namespace } from "vuex-class";
import FscModal from "@/components/Modal/FscModal.vue";

const LMSQuestionTutorModule = namespace("lms");
@Component({
  components: { FscModal, AbortButton, SaveButton, ActionButton },
})
export default class QuestionExplanation extends mixins() {
  public name = "QuestionExplanation.vue";

  private isEditing = false;
  private text = "??";
  private edit = "??";
  private officialNumber: any = "1";
  public resetClicked = false;

  @LMSQuestionTutorModule.Getter("getTheoryQuestionExplanationIsLoading")
  public explanationIsLoading!: boolean;

  @LMSQuestionTutorModule.Getter("getTheoryQuestionExplanation")
  public explanation!: any;

  @LMSQuestionTutorModule.Action("explanation")
  public getExplanationAction!: any;

  @LMSQuestionTutorModule.Action("explanationUpsert")
  public explanationUpsert!: any;

  @LMSQuestionTutorModule.Action("explanationReset")
  public explanationReset!: any;

  public async setSelectedQuestionOfficialNumber(officialNumber: any) {
    this.officialNumber = officialNumber;
    await this.getExplanationAction(this.officialNumber);
    this.text = this.explanation;
    this.edit = this.text;
  }

  public penClicked() {
    this.isEditing = !this.isEditing;
  }

  public onClose() {
    this.isEditing = false;
    this.$emit("on-close");
  }

  public async onSave() {
    this.text = this.edit;
    this.isEditing = false;

    await this.explanationUpsert({
      officialNumber: this.officialNumber,
      explanation: this.text,
    });

    this.$emit("on-explanation-change");
  }

  public resetRequest() {
    this.$bvModal.show("reset-to-default-modal");
  }

  public async reset() {
    await this.explanationReset({
      officialNumber: this.officialNumber,
    });

    this.$emit("on-explanation-change");

    await this.setSelectedQuestionOfficialNumber(this.officialNumber);
  }

  public resetCancelled() {
    this.$bvModal.hide("reset-to-default-modal");
  }
}
