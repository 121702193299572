







import { Component, Vue, Prop } from "vue-property-decorator";
import ProgressBarCircle from "@/components/ProgressBar/ProgressBarCircle.vue";
import ProgressBarLinear from "@/components/ProgressBar/ProgressBarLinear.vue";

@Component({
  components: { ProgressBarLinear, ProgressBarCircle },
})
export default class DashboardUpcoming extends Vue {
  public name = "LMSDashboardCover";

  @Prop({ default: () => [] })
  public upcomingTheoryCourses!: any;
}
