



































































































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import Question from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/Question.vue";
import QuestionList from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/QuestionList.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import SearchBar from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/SearchBar.vue";
import QuestionExplanation from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/QuestionExplanation.vue";
import MyLastSearchTerms from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/MyLastSearchTerms.vue";
import MyLastQuestions from "@/views/LMSExamQuestions/QuestionTutor/QuestionCard/MyLastQuestions.vue";
import LMSDashboardLink from "@/views/LMSDashboard/LMSDashboardLink.vue";

@Component({
  components: {
    LMSDashboardLink,
    MyLastQuestions,
    MyLastSearchTerms,
    QuestionExplanation,
    SearchBar,
    SaveButton,
    AbortButton,
    ActionButton,
    Question,
    QuestionList,
    FscCard,
  },
})
export default class QuestionTutor extends mixins() {
  public name = "QuestionTutor";
  mounted() {
    // todo handle refresh button
  }

  public selectedQuestion: any = null;
  public questionExplanationClosed: boolean | null = false;
  public submittedQuestionTerm: string | null = null;

  protected actualQuery: any = {};

  public onSearchSubmitted(submittedQuestionTerm: any, showSingleQuestion: boolean): void {
    this.selectedQuestion = null;
    this.submittedQuestionTerm = submittedQuestionTerm;

    this.$nextTick(() => {
      (this.$refs.QuestionList as QuestionList).searchWithToken(this.submittedQuestionTerm, !showSingleQuestion);
    });

    this.$nextTick(() => {
      (this.$refs.SearchBarOnSearched as SearchBar).setSearchTerm(this.submittedQuestionTerm);
    });

    if (showSingleQuestion) {
      this.$nextTick(() => {
        let items = (this.$refs.QuestionList as QuestionList).getQuestions();
        if (items.length > 0) {
          this.onQuestionSelected(items[0]);
        }
      });
    }
  }

  public onQuestionSelected(selectedQuestion: any): void {
    this.selectedQuestion = selectedQuestion;
    // Wait for Vue to render the selected question
    this.$nextTick(() => {
      (this.$refs.Question as Question).setSelected(this.selectedQuestion);
      (this.$refs.QuestionExplanation as QuestionExplanation).setSelectedQuestionOfficialNumber(this.selectedQuestion.officialNumber);
    });
  }

  public onExplanationChange(): void {
    this.$nextTick(() => {
      (this.$refs.MyLastQuestions as MyLastQuestions).load();
    });
  }

  public closeQuestionPreviewAndEdit(): void {
    this.questionExplanationClosed = true;
    this.selectedQuestion = null;
    this.$nextTick(() => {
      if (this.submittedQuestionTerm) {
        (this.$refs.QuestionList as QuestionList).setToken(this.submittedQuestionTerm, this.actualQuery);
      }
    });
  }

  public setActualQuery(query: any): void {
    this.actualQuery = query;
  }
}
