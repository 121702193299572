













import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import { namespace } from "vuex-class";

const LMSQuestionTutorModule = namespace("lms");

@Component({
  components: { FscCard, ActionButton },
})
export default class MyLastQuestions extends mixins() {
  // lastly modified question of explanations
  public name = "MyLastQuestions.vue";

  @LMSQuestionTutorModule.Action("explanationLastTenOfUser")
  public findExplanationLastTenOfUser!: any;

  @LMSQuestionTutorModule.Getter("getUserLastModifiedExplanations")
  public getUserLastModifiedExplanations!: any;

  public list: any = null;

  async mounted() {
    await this.load();
  }

  public handleClick(key: string) {
    this.$emit("lms-select-question", key, true);
  }

  public async load() {
    await this.findExplanationLastTenOfUser();
    // todo front get wont get properly!
    this.list = this.$store.state.lms.standardTheoryQuestionUserLastModifiedExplanations;
  }
}
