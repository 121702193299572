


















import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import { namespace } from "vuex-class";

const LMSQuestionTutorModule = namespace("lms");
@Component({
  components: { FscCard, ActionButton },
})
export default class MyLastSearchTerms extends mixins() {
  public name = "MyLastSearchTerms.vue";

  @LMSQuestionTutorModule.Getter("getUserLastExplanationSearches")
  public getUserLastExplanationSearches!: any;

  @LMSQuestionTutorModule.Action("loadSearchTerms")
  public loadSearchTerms!: any;

  async mounted() {
    await this.loadSearchTerms();
  }

  public handleClick(key: string) {
    this.$emit("lms-search-term-selected", key, true);
  }
}
